import classNames from 'classnames'

import styles from './Container.module.scss'

type Props = {
  article?: boolean
  children: React.ReactNode
  className?: string
  small?: boolean
  wide?: boolean
}

const Container = ({ article, children, className, small, wide }: Props) => (
  <div
    className={classNames(styles.container, {
      [className as string]: className,
      [styles.article]: article,
      [styles.small]: small,
      [styles.wide]: wide,
    })}
  >
    {children}
  </div>
)

export default Container
