import classNames from 'classnames'
import htmlReactParser from 'html-react-parser'
import { isValidElement, ReactNode } from 'react'
import reactStringReplace from 'react-string-replace'
import YouTube from 'react-youtube'

import styles from './SimpleHeader.module.scss'

type Props = {
  children: string | ReactNode[]
  className?: string
}

const SimpleHeader = ({ children, className }: Props) => {
  const newChildren = reactStringReplace(children, /https:\/\/www.youtube.com\/watch\?v=/, (match) => (
    <YouTube videoId={match} />
  ))

  return (
    <h2 className={classNames(styles.header, className)}>
      {newChildren?.map((child) => (isValidElement(child) ? child : child ? htmlReactParser(`${child}`) : null))}
    </h2>
  )
}

export default SimpleHeader
