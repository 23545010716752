import classNames from 'classnames'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import Icon from '@/components/Icon/Icon'
import { useLoadAds } from '@/contexts/ads'
import cookieService from '@/services/cookie.service'
import { useAppSelector } from '@/store/hooks'
import useDirectories from '@/utils/useDirectories'

import styles from './AsteriAd.module.scss'

const maxAge = 300

type Props = {
  region?: string
}

const AsteriAd = ({ region }: Props) => {
  const userRegion = useAppSelector((state) => state?.user.region)
  const loadAds = useLoadAds()
  const [cookies, _] = useCookies(['asteriClosed'])
  const { dir } = useDirectories()
  const [isClient, setIsClient] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  // If region is defined, only show the ad if the user's region matches
  const isCorrectRegion = region === undefined || userRegion === region

  const handleClose = () => {
    setIsOpen(false)
    cookieService.set('asteriClosed', true, { maxAge })
    setTimeout(() => {
      setIsOpen(true)
      cookieService.remove('asteriClosed')
    }, 1000 * maxAge)
  }

  useEffect(() => {
    setIsClient(true)
    setIsOpen(cookies.asteriClosed !== true)
  }, [])

  if (!loadAds || !isClient || !isOpen || !isCorrectRegion) return null

  const pageHasDesktopAdhesion = [undefined, 'articles'].includes(dir)

  return (
    <>
      <Script
        async
        src={`https://asteriresearch.com/livestream-latest.min.js?v=${new Date().getTime()}`}
        type='text/javascript'
      />
      <div
        data-blocklist-universal-player-desktop='1'
        data-blocklist-universal-player-mobile='1'
        id='mediavine-settings'
      />
      <div className={classNames(styles.container, { [styles.hasDesktopAdhesion]: pageHasDesktopAdhesion })}>
        <div className='h-100 position-relative w-100'>
          <Icon className={styles.close} icon='times' onClick={handleClose} />
          <div
            // @ts-ignore
            asterilivestream_height={window.innerWidth < 768 ? 90 + 30 : 169 + 30}
            asterilivestream_profile='106_86'
            asterilivestream_width={window.innerWidth < 768 ? 160 : 300}
          />
        </div>
      </div>
    </>
  )
}

export default AsteriAd
