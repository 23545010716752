import classNames from 'classnames'
//import { useEffect, useState } from 'react'

//import Ad from '@/components/Ad/Ad'
import { useLoadAds } from '@/contexts/ads'

import styles from './Leaderboard.module.scss'

const Leaderboard = () => {
  const loadAds = useLoadAds()
  //const [randomRoll, setRandomRoll] = useState<number>(0)

  /* useEffect(() => {
    setRandomRoll(Math.random())
    const interval = setInterval(() => {
      setRandomRoll(Math.random())
    }, 30000)
    return () => clearInterval(interval)
  }, []) */

  if (loadAds === false) return null

  /* Ad Setup:
     - 10.0% SCG

     - Half of remainder split between SCM
     - Other half of remainder goes to EDHREC Patreon
  */

  /* const ad =
    randomRoll === 0 ? null : randomRoll < 0.05 ? (
      <Ad id='scg1' />
    ) : randomRoll < 0.1 ? (
      <Ad id='scg2' />
    ) : randomRoll < 0.1 + 0.9 * (1 / 16) ? (
      <Ad id='archidekt' />
    ) : randomRoll < 0.1 + 0.9 * (2 / 16) ? (
      <Ad id='cardgamer' />
    ) : randomRoll < 0.1 + 0.9 * (3 / 16) ? (
      <Ad id='ch' />
    ) : randomRoll < 0.1 + 0.9 * (4 / 16) ? (
      <Ad id='csb' />
    ) : randomRoll < 0.1 + 0.9 * (5 / 16) ? (
      <Ad id='edhrecap' />
    ) : randomRoll < 0.1 + 0.9 * (6 / 16) ? (
      <Ad id='edhrecast' />
    ) : randomRoll < 0.1 + 0.9 * (7 / 16) ? (
      <Ad id='fabrec' />
    ) : randomRoll < 0.1 + 0.9 * (7.25 / 16) ? (
      <Ad id='spellifyDark1' />
    ) : randomRoll < 0.1 + 0.9 * (7.5 / 16) ? (
      <Ad id='spellifyDark2' />
    ) : randomRoll < 0.1 + 0.9 * (7.75 / 16) ? (
      <Ad id='spellifyLight1' />
    ) : randomRoll < 0.1 + 0.9 * (8 / 16) ? (
      <Ad id='spellifyLight2' />
    ) : (
      <Ad id='edhrecPatreon' />
    ) */

  return (
    <div className={styles.container}>
      {loadAds === true && (
        <>
          <div className='mvLeaderboard' />
          <div className={classNames(styles.mvMobile, 'mv_slot_target')} data-slot='LeaderboardMobile' />
        </>
      )}
    </div>
  )
}

export default Leaderboard
