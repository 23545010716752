import classNames from 'classnames'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import Icon from '@/components/Icon/Icon'
import { useLoadAds } from '@/contexts/ads'
import cookieService from '@/services/cookie.service'
import useDirectories from '@/utils/useDirectories'

import styles from './Twitch.module.scss'

const channel = 'edhrecast'
const cookieKey = 'asteriClosed'
const maxAge = 300

const Twitch = () => {
  const [cookies, _] = useCookies([cookieKey])
  const { dir } = useDirectories()
  const loadAds = useLoadAds()
  const [isClient, setIsClient] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => {
    setIsOpen(false)
    cookieService.set(cookieKey, true, { maxAge })
    setTimeout(() => {
      setIsOpen(true)
      cookieService.remove(cookieKey)
    }, 1000 * maxAge)
  }

  useEffect(() => {
    setIsClient(true)
    setIsOpen(cookies[cookieKey] !== true)
  }, [])

  useEffect(() => {
    if (isClient) {
      const initializeTwitchEmbed = () => {
        try {
          const height = window.innerWidth < 768 ? 90 : 169
          const width = window.innerWidth < 768 ? 160 : 300
          // @ts-ignore
          new window.Twitch.Embed('twitch-embed', {
            channel,
            height,
            layout: 'video',
            muted: true,
            width,
          })
        } catch (e) {
          console.error(e)
        }
      }

      // @ts-ignore
      if (window.Twitch) {
        initializeTwitchEmbed()
      } else {
        window.addEventListener('twitchScriptLoaded', initializeTwitchEmbed)
      }

      return () => {
        window.removeEventListener('twitchScriptLoaded', initializeTwitchEmbed)
      }
    }
  }, [isClient])

  if (!loadAds || !isOpen) return null

  const pageHasDesktopAdhesion = [undefined, 'articles'].includes(dir)

  return (
    <>
      <Script
        async
        onLoad={() => {
          const event = new Event('twitchScriptLoaded')
          window.dispatchEvent(event)
        }}
        src='https://embed.twitch.tv/embed/v1.js'
        type='text/javascript'
      />
      <div className={classNames(styles.container, { [styles.hasDesktopAdhesion]: pageHasDesktopAdhesion })}>
        <div className='h-100 position-relative w-100'>
          <Icon className={styles.close} icon='times' onClick={handleClose} />
          <div className={styles.embed} />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.embed} id='twitch-embed' />
      </div>
    </>
  )
}

export default Twitch
