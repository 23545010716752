import Link from 'next/link'

import Container from '@/components/Container/Container'
import { ExplicitHead } from '@/components/DefaultHead/DefaultHead'
import Main from '@/components/Main/Main'
import Panel from '@/components/Panel/Panel'
import SimpleHeader from '@/components/SimpleHeader/SimpleHeader'

const NotFoundPage = () => (
  <Main>
    <Container small>
      <ExplicitHead description='Page not found.' keywords='404' noindex title='Not Found' />
      <SimpleHeader>404 Page Not Found</SimpleHeader>
      <Panel>
        <p>The error has been recorded. New cards take a few days to show up on EDHREC.</p>
        <p>
          Otherwise, you can also <Link href='/bug-report'>report a bug</Link> to help us fix it faster.
        </p>
      </Panel>
    </Container>
  </Main>
)

export default NotFoundPage
