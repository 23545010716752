import Head from 'next/head'
import { useRouter } from 'next/router'

import metaImage from '@/assets/meta_image.jpg'
import { GenericPageProps } from '@/types/page'

export type ExplicitHeadProps = {
  description: string
  imageUrl?: string
  keywords: string
  noindex?: boolean
  title: string
}

export const ExplicitHead = ({ description, imageUrl, keywords, noindex, title }: ExplicitHeadProps) => {
  const router = useRouter()

  title = title.includes('EDHREC') ? title : `${title} | EDHREC`
  const image = imageUrl ? imageUrl : metaImage.src
  const url = `https://edhrec.com${router.asPath === '/' ? '' : router.asPath}`

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta content={title} name='title' />
        <meta content={title} property='og:title' />
        <meta content={description} name='description' />
        <meta content={description} property='og:description' />
        <meta content={keywords} name='keywords' />
        <meta content={keywords} property='og:keywords' />
        {noindex ? (
          <meta content='noindex' name='robots' />
        ) : (
          <>
            <link href={url} rel='canonical' />
            <meta content={url} property='og:url' />
          </>
        )}
        <meta content='website' property='og:type' />
        <meta content='EDHREC' property='og:site_name' />
        <meta content={image} property='og:image' />
        <meta content='summary_small_image' name='twitter:card' />
        <meta content='EDHREC' name='twitter:image:alt' />

        {/* preconnect to resources we're very likely going to need */}
        {/* no more than 6. look at "Connection View" in WebPageTest */}
        <link href='https://json.edhrec.com' rel='preconnect' />
        <link href='https://edhrecstatic.com' rel='preconnect' />
        <link href='https://cards.scryfall.io' rel='preconnect' />
        <link href='https://scripts.mediavine.com' rel='preconnect' />
        <link href='https://www.googletagmanager.com' rel='preconnect' />
        <link href='https://www.youtube.com' rel='preconnect' />

        {/* dns-prefetch the same things as a fallback */}
        {/* "Implementing dns-prefetch fallback in the same <link> tag causes a bug in Safari where preconnect gets cancelled." */}
        <link href='https://json.edhrec.com' rel='dns-prefetch' />
        <link href='https://edhrecstatic.com' rel='dns-prefetch' />
        <link href='https://cards.scryfall.io' rel='dns-prefetch' />
        <link href='https://scripts.mediavine.com' rel='dns-prefetch' />
        <link href='https://www.googletagmanager.com' rel='dns-prefetch' />
        <link href='https://www.youtube.com' rel='dns-prefetch' />

        {/* dns-prefetch less critical stuff we might need */}
        <link href='https://keywords.mediavine.com' rel='dns-prefetch' />
        <link href='https://exchange.mediavine.com' rel='dns-prefetch' />
        <link href='https://secure.gravatar.com' rel='dns-prefetch' />
        <link href='https://commandersheraldassets.com' rel='dns-prefetch' />
        <link href='https://www.google-analytics.com' rel='dns-prefetch' />
      </Head>
    </>
  )
}

type Props = {
  data: GenericPageProps['data']
}

const DefaultHead = ({ data }: Props) => (
  <ExplicitHead
    description={data.container.description}
    imageUrl={data.container?.json_dict?.card?.image_uris[0]?.art_crop}
    keywords={data.container.keywords}
    title={data.container.title}
  />
)

export default DefaultHead
